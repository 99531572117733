<template>
  <div :class="{'bg-blue-200': state.selectedProduct === op.product_id, 'text-sm': small}" class="flex py-1 border-b bg-white hover:bg-gray-100" @click="state.selectedProduct = state.selectedProduct === op.product_id ? 0 : op.product_id">
    <div class="flex-none font-mono text-gray-500 px-2 py-1">{{ op.sku }}</div>
    <div class="flex-1 px-2 py-1">
      <span v-html="fomattedName(op.name)" /> <br v-if="small!==true"><span class="text-blue-600" v-html="fomattedName(op.options)" />
    </div>
    <div class="flex-none w-12 px-2 py-1 font-mono text-right">
      <span :class="{ 'text-red-600': op.amount > 1 }">{{ op.amount }}</span>
    </div>
  </div>
</template>

<script>
import store from "../store"

export default {
  name: 'OrderedProductListItem',
  data() {
    return {
      state: store.state
    }
  },
  props: {
    op: Object,
    small: Boolean
  },
  methods: {
    fomattedName(name) {
      name = name.replace('Protein 80', '<span class="font-bold text-green-700">Protein 80</span>')
      name = name.replace('Isolate 90', '<span class="font-bold text-pink-700">Isolate 90</span>')
      name = name.replace('500 g', '<span class="font-bold text-blue-500">500 g</span>')
      name = name.replace('1 kg', '<span class="font-bold text-blue-800">1 kg</span>')
      name = name.replace('Skin Aid', '<span class="font-bold text-purple-800">Skin Aid</span>')
      name = name.replace('12 g', '<span class="font-bold underline text-orange-800">12 g</span>')
      return name;
    }
  }
}
</script>
