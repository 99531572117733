import axios from "axios";
import router from '../router'

let api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
});

api.defaults.withCredentials = true

api.get('/sanctum/csrf-cookie')

api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
        router.push({name: 'login'})
    }

    return Promise.reject(error);
});

export default api;